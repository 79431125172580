/**
 * MyWalletTokensTableItem
 * @author Lopes
 */
import {TokensTableItemMarketInformation} from '@/model/resource/TokensTableItemMarketInformation'
import {BSNeo3Constants, FlamingoSwapHelper} from '@cityofzion/bs-neo3'
import {TokensTableItem} from '@/model/resource/TokensTableItem'

export class MyWalletTokensTableItem extends TokensTableItem {
  imageUrl: string | null = null
  symbol: string | null = null
  marketInformation: TokensTableItemMarketInformation | null = null
  amount: number = 0

  swappableTokensSymbol = FlamingoSwapHelper.listSwappableTokensSymbol(
    BSNeo3Constants.DEFAULT_NETWORK
  )

  get isSwappable() {
    return this.swappableTokensSymbol.includes(this.symbol!)
  }

  get balanceFormatted() {
    return `${Number(this.amount).toLocaleString('en-US', {
      maximumFractionDigits: 10,
      minimumFractionDigits: 0,
    })} ${this.symbol}`
  }

  get amountInDollar() {
    return this.amount * Number(this.marketInformation?.currentPrice ?? 0)
  }

  get priceInDollar() {
    return Number(this.marketInformation?.currentPrice ?? 0)
  }

  get valueFormatted() {
    return Number(this.amount * this.priceInDollar).toLocaleString('en-US', {
      maximumFractionDigits: 10,
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'USD',
    })
  }
}
