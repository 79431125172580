





















































import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {$} from '@/facade'
import NetworkTag from '@/components/NetworkTag.vue'

@Component({
  components: {NetworkTag},
})
export default class TokenCellRender extends MixinScreenSize {
  @Prop({type: String}) imageUrl?: string
  @Prop({type: String}) symbol?: string
  @Prop({type: String}) slug?: string
  @Prop({type: String}) blockchainTagName?: string

  async goToTokenOrError() {
    if (this.slug) {
      const params = {
        id: this.slug,
      }

      await $.router.push({name: 'dappDetailedView', params})
    } else this.$toast.abort('components.TokenCellRender.dappError')
  }
}
